.search-results-height
{
    flex: 1;
    max-height: calc(100vh - 334px);
    overflow: auto;
    .space-card-container
    {
        padding-top: 25px;
        display: flex;
        flex-wrap: wrap;
    }
}

.search-criteria-changed-alert .iconics-alert-text
{
    width: auto !important;
}


