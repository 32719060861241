.edit-visit
{
}

.edit-visit__question
{
    cursor: pointer;
    padding-top: 16px;
    padding-bottom: 16px;
}

    .edit-visit__question:hover
    {
        background-color: rgba(46, 123, 185, 0.08);
    }

    .edit-visit__question.edit-visit--heading,
    .edit-visit__question.edit-visit--sub-heading
    {
        cursor: initial;
        font-size: 14px;
        text-decoration: underline;
    }

    .edit-visit__question.edit-visit--image
    {
        cursor: initial;
        height: 100px;
        padding: 8px 0 4px 0;
    }

        .edit-visit__question.edit-visit--heading:hover,
        .edit-visit__question.edit-visit--sub-heading:hover,
        .edit-visit__question.edit-visit--image:hover
        {
            background-color: initial;
        }

.edit-visit__question-info,
.edit-visit__answer-info
{
    margin-right: 16px;
    font-family: 'Source Sans Pro';
    font-weight: 600;
    font-size: 0.875rem;
    color: rgb(110, 111, 119);
    word-break: break-word;
}

.edit-visit__question-checkbox > div
{
    display: inline;
}

.edit-visit__question-checkbox span
{
    padding: 0;
}

.edit-visit__question-checkbox label
{
    margin-left: -4px;
}

.edit-visit__question-divider
{
    margin-top: 0;
    margin-bottom: 0;
}

.edit-visit__helper-text
{
    color: var(--ui-text-alternate);
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
}
