.spaceinfo-top-img img{
    width: 100%;
    height: 200px!important;
    object-fit: cover;
}
.space-guide-card{
    background-color:var(--ui-background);
    border-radius:0.5rem;
}
.common-space-info-card-h{
    height: calc(100vh - 388px);
    overflow-y: auto;
}

.spaceinfo-container{
    box-sizing: border-box;
}
.space-info-dialog-with-custom-info__star-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    background-color: var(--ui-background-alternate);
    height: 40px;
    border-radius: 50%;
    padding: 12px;
    z-index: 10;
    cursor: pointer;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}