.list-visitor-types__list-item
{
    border: 1px solid #DCE1E5;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    align-items: center;
    margin-bottom: 10px;
}

.list-visitor-types__list-item--selected
{
    background-color: #DCE1E5;
}

.noContentBox
{
    border: 1px dashed #DCE1E5;
    padding: 80px;
    text-align: center;
}