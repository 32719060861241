@import '../../../../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-icons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-buttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-lists/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../../../../node_modules/@syncfusion/ej2-richtexteditor/styles/material.css';


.syncfusion-rich-text-editor .e-dropdown-btn .e-rte-dropdown-btn-text
{
    font-family: 'Source Sans Pro';
    font-weight: bold !important;
}

.e-rte-dropdown-items
{
    font-family: 'Source Sans Pro';
    font-weight: 'bold';
}

.e-item
{
    font-family: 'Source Sans Pro';
    font-weight: bold;
}

.e-item .e-paragraph .e-active
{
    font-family: 'Source Sans Pro';
    font-weight: bold;
}